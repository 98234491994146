<script>
import get from 'lodash-es/get'
import modal from '/~/core/mdl'
import { useQrConnect } from '/~/extensions/qr-connect/composables'
import BaseButton from '/~/components/base/button/base-button.vue'
import { useUser } from '/~/composables/user'
import QrLayout from '../../components/qr-layout.vue'
import QrMenuScanner from '../../components/qr-menu-scanner.vue'

export default {
  name: 'qr-scan',
  components: {
    QrLayout,
    QrMenuScanner,
    BaseButton,
  },
  setup() {
    const { user } = useUser()
    const { processQRCode } = useQrConnect()

    return {
      user,
      processQRCode,
    }
  },
  data() {
    return {
      activated: false,
      processing: false,
    }
  },
  computed: {
    events() {
      return [
        'Entering and exiting the building',
        'Arriving at your workspace',
        'Attending a meeting room',
      ]
    },
  },
  watch: {
    '$route.query.qrConnectCode'(value) {
      if (value) {
        this.processQR(value)
      }
    },
  },
  created() {
    const {
      query: { qrConnectCode },
    } = this.$route

    if (qrConnectCode) {
      this.processQR(qrConnectCode)
    }
  },
  methods: {
    getParameterByName(name, url) {
      if (!url) {
        url = window.location.href
      }

      name = name.replace(/[[\]]/g, '\\$&')

      const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
      const results = regex.exec(url)

      if (!results) {
        return null
      }
      if (!results[2]) {
        return ''
      }
      return decodeURIComponent(results[2].replace(/\+/g, ' '))
    },
    scanQR(qrString) {
      const code = this.getParameterByName('qrConnectCode', qrString)

      if (code) {
        this.processQR(code)
      }
    },
    async processQR(code) {
      this.processing = true

      try {
        const [error, response] = await this.processQRCode({
          qrCode: code,
          userExternalId: this.user.externalId,
          onComplete: this.onCompleteScan,
        })

        if (this.$route.query.qrConnectCode) {
          const query = { ...this.$route.query }

          delete query.qrConnectCode
          this.$router.replace({ query })
        }

        if (error) {
          this.$notify({
            text: get(
              error,
              'message',
              'Something went wrong, please try again'
            ),
            type: 'error',
            duration: 10000,
          })
        }

        return [error, response]
      } catch (error) {
        console.error('qr-connect', error)
      } finally {
        if (this.activated) {
          this.activated = false
        }
        this.processing = false
      }
    },
    onCompleteScan(success) {
      if (success) {
        this.$router.push({ hash: '#profile-activity' })
      }
    },
    onQrManually() {
      modal.show('qr-code-manually', {
        props: {
          processQR: this.processQR,
        },
      })
    },
  },
}
</script>

<template>
  <qr-layout :hide-footer="true">
    <p class="mb-2.5 text-center">
      Press Activate Camera to scan QR code when:
    </p>
    <ul class="text-center">
      <li v-for="event in events" :key="event">
        <b>> {{ event }}</b>
      </li>
    </ul>

    <qr-menu-scanner
      :activated.sync="activated"
      :processing.sync="processing"
      @qr-decoded="scanQR"
    />

    <p class="mt-5 text-center">
      Having problem scanning?
      <base-button look="link" @click="onQrManually">
        Enter QR code manually
      </base-button>
    </p>
  </qr-layout>
</template>
